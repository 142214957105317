import EventManager, {Unsubscriber} from "../../event/EventManager";
import RenderContext from "../context/RenderContext";
import {
    Event,
    EventType,
    IBendpointCreateEvent,
    IBendpointMoveEvent,
    IChartEvent,
    DiagramZoomEvent,
    IModelUpdatedEvent,
    INodeEvent,
    INodeResizeEvent,
} from "../../event/Event";
import * as d3 from 'd3';
import {Area, EMPTY_AREA, Point} from "../util/GeometryUtils";
import ChartGroup from "../common/ChartGroup";
import {DiagramEditorUtils} from "../util/DiagramEditorUtils";
import {CONTENT_AREA_ID} from "../../../components/diagrameditor/DiagramEditorComponent";

export const PAPER_GROUP_ID = "__diagram-editor-paper-group__"
export const PAPER_RECT_ID = "__diagram-editor-paper__"

export const PAPER_PADDING_SIZE = 20;
export const DEFAULT_PAPER_FILL_COLOR = "white";
export const TRANSPARENT_PAPER_FILL = "none";

export default class SvgPaperManager {

    private eventManager: EventManager;
    private renderContext?: RenderContext;
    private lastPaperArea?: Area;
    private lastZoom: number;
    private transparent: boolean;

    private unsubscribers: Array<Unsubscriber> = [];

    constructor(eventManager: EventManager) {
        this.eventManager = eventManager;
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_RENDERED, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_NODES_RERENDERED, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CHART_CONNECTIONS_RERENDERED, this.handleChartEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_STARTED, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_IN_PROGRESS, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_FINISHED, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_MOVE_CANCELLED, this.handleBendpointMoveEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_CREATE_STARTED, this.handleBendpointCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_CREATE_IN_PROGRESS, this.handleBendpointCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_CREATE_FINISHED, this.handleBendpointCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.CONNECTION_BENDPOINT_CREATE_CANCELLED, this.handleBendpointCreateEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_STARTED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_IN_PROGRESS, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_FINISHED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_MOVE_CANCELLED, this.handleNodeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_STARTED, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_IN_PROGRESS, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_FINISHED, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.NODE_RESIZE_CANCELLED, this.handleNodeResizeEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.DIAGRAM_ZOOM_UPDATED, this.handleChartZoomEvent.bind(this)));
        this.unsubscribers.push(this.eventManager.subscribeListener(EventType.MODEL_UPDATED, this.handleModelUpdatedEvent.bind(this)));

        this.lastZoom = 1;
        this.transparent = false;
    }

    destroy() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }
    }

    public init(renderContext: RenderContext) {
        this.renderContext = renderContext;
        const paper = this.renderContext.svgElementManager.getSvgSelection()
            .append("g")
            .lower()
            .attr("id", PAPER_GROUP_ID);
        if (!this.renderContext.isPreviewOrDashboard()) {
            paper.classed("svgPaperShadow", true);
        }
    }

    public getPaperArea() {
        return this.lastPaperArea;
    }

    public isTransparent(): boolean {
        return this.transparent;
    }

    public setTransparent(transparent: boolean) {
        this.transparent = transparent;
    }

    handleChartEvent(event: IChartEvent) {
        if (event.type === EventType.CHART_RENDERED) {
            SvgPaperManager.getPaperGroupSelection()
                .append("rect")
                .attr("id", PAPER_RECT_ID)
                .attr("fill", this.transparent ? TRANSPARENT_PAPER_FILL : DEFAULT_PAPER_FILL_COLOR);
            this.syncPaperWithDiagramGroup(event);
        }
        if (event.type === EventType.CHART_NODES_RERENDERED || event.type === EventType.CHART_CONNECTIONS_RERENDERED) {
            this.syncPaperWithDiagramGroup(event);
        }
    }

    handleChartZoomEvent(event: DiagramZoomEvent) {
        if (event.type === EventType.DIAGRAM_ZOOM_UPDATED) {
            this.lastZoom = event.actualZoom;
            this.syncPaperWithDiagramGroup(event);
        }
    }

    handleNodeResizeEvent(event: INodeResizeEvent) {
        if (event.type === EventType.NODE_RESIZE_STARTED || event.type === EventType.NODE_RESIZE_IN_PROGRESS ||
            event.type === EventType.NODE_RESIZE_FINISHED || event.type === EventType.NODE_RESIZE_CANCELLED
        ) {
            this.syncPaperWithDiagramGroup(event);
        }
    }

    handleNodeEvent(event: INodeEvent) {
        if (event.type === EventType.NODE_MOVE_STARTED || event.type === EventType.NODE_MOVE_IN_PROGRESS ||
            event.type === EventType.NODE_MOVE_FINISHED || event.type === EventType.NODE_MOVE_CANCELLED) {
            this.syncPaperWithDiagramGroup(event);
        }
    }

    handleModelUpdatedEvent(event: IModelUpdatedEvent) {
        if (event.type === EventType.MODEL_UPDATED) {
            this.syncPaperWithDiagramGroup(event);
        }
    }

    handleBendpointMoveEvent(event: IBendpointMoveEvent) {
        this.syncPaperWithDiagramGroup(event);
    }

    handleBendpointCreateEvent(event: IBendpointCreateEvent) {
        this.syncPaperWithDiagramGroup(event);
    }

    private syncPaperWithDiagramGroup(event: Event) {
        if (this.renderContext) {
            let paperArea = ChartGroup.computeNodesConnectionsAreaRelativeTo(SvgPaperManager.getPaperGroupNode(), true);
            if (paperArea) {
                this.addPaperPadding(paperArea);
            } else {
                paperArea = EMPTY_AREA;
            }

            SvgPaperManager.getPaperSelection()
                .on("contextmenu", event => {
                    event.preventDefault();
                    const diagramGroupPoint = this.convertOuterPointToGroup(event);
                    this.eventManager.publishEvent({
                        type: EventType.PAPER_SHOW_CONTEXT_MENU,
                        event: event,
                        transformedClientCoordinates: [diagramGroupPoint.x, diagramGroupPoint.y]
                    });
                });

            SvgPaperManager.getContentArea()
                .on("contextmenu", event => {
                    event.preventDefault();
                    if (this.clickIsInsidePaper(event.clientX, event.clientY)) {
                        return;
                    }
                    const diagramGroupPoint = this.convertOuterPointToGroup(event);
                    this.eventManager.publishEvent({
                        type: EventType.PAPER_SHOW_CONTEXT_MENU,
                        event: event,
                        transformedClientCoordinates: [diagramGroupPoint.x, diagramGroupPoint.y]
                    });
                });

            const previousPaperArea = this.lastPaperArea || paperArea;
            this.lastPaperArea = paperArea;
            SvgPaperManager.updatePaperDimensions(paperArea);

            this.publishSvgPaperEvent(previousPaperArea, this.lastPaperArea, event);
        }
    }

    private clickIsInsidePaper(x: number, y: number): boolean {
        const boundingClientRect = SvgPaperManager.getPaperSelection().node()?.getBoundingClientRect();
        if (boundingClientRect) {
            const area = Area.fromDOMRect(boundingClientRect);
            return area.containsPoint(Point.of(x, y));
        }
        return false;
    }

    private convertOuterPointToGroup(event: any) {
        const diagramGroupNode = this.renderContext?.svgElementManager.getDiagramGroupSelection().node() as SVGGElement;
        const svgNode = this.renderContext?.svgElementManager.getSvg() as SVGSVGElement;
        return DiagramEditorUtils.convertOuterPointToGroup(new Point(event.clientX, event.clientY), diagramGroupNode, svgNode);
    }

    private publishSvgPaperEvent(previousPaperArea: Area, newPaperArea: Area, causeEvent: Event) {
        this.eventManager.publishEvent({
            type: EventType.SVG_PAPER_AREA_UPDATED,
            causeEvent: causeEvent,
            previousPaperArea: previousPaperArea,
            newPaperArea: newPaperArea,
        });
    }

    private static updatePaperDimensions(area: Area) {
        SvgPaperManager.getPaperSelection()
            .attr("x", area.x)
            .attr("y", area.y)
            .attr("width", area.w)
            .attr("height", area.h);
    }

    private static getPaperGroupSelection() {
        return d3.select("#"+PAPER_GROUP_ID) as d3.Selection<SVGGElement, unknown, HTMLElement, any>;
    }

    static getPaperGroupNode() {
        return d3.select("#"+PAPER_GROUP_ID).node() as SVGGElement;
    }

    private static getPaperSelection() {
        return d3.select("#"+PAPER_RECT_ID) as d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
    }

    private static getContentArea() {
        return d3.select("#"+CONTENT_AREA_ID) as d3.Selection<SVGRectElement, unknown, HTMLElement, any>;
    }

    private addPaperPadding(area: Area) {
        const padding = PAPER_PADDING_SIZE * this.lastZoom;
        area.x -= padding;
        area.y -= padding;
        area.w += (2 * padding);
        area.h += (2 * padding);
    }
}
