import {ModelManager} from "../../manager/ModelManager";
import * as d3 from "d3";
import {Area, Interval, Point} from "../../util/GeometryUtils";
import ChartGroup, {ChartGroupType} from "../../common/ChartGroup";
import {
    DEFAULT_FONT_COLOR,
    DEFAULT_FONT_FAMILY,
    DEFAULT_FONT_SIZE,
    DEFAULT_FONT_STYLE,
    DEFAULT_LINE_COLOR
} from "../../common/UIConstants";
import {IDiagramConnectionDto} from "../../../apis/diagram/IDiagramConnectionDto";
import {ColorRenderer} from "../color/ColorRenderer";
import {TEXT_CLASS_NAME} from "./AbstractConnectionRenderer";
import {CONNECTION_TEXT_WIDTH} from "../../manager/ConnectionLabelManager";

export enum ConnectionOrientation {
    L = "L",   // LEFT
    R = "R",   // RIGHT
    U = "U",   // UP
    D = "D",   // DOWN
    LU = "LU", // LEFT-UP
    LD = "LD", // LEFT_DOWN
    RU = "RU", // RIGHT_UP
    RD = "RD", // RIGHT_DOWN
}

export enum Axis {
    X,
    Y,
}

export interface LinePath {
    path: string,
    points: Array<Point>,
}

export class LinePathUtils {

    static createLines(linePath: LinePath): Array<[Point, Point]> {
        const lines: Array<[Point, Point]> = [];
        if (linePath && linePath.points && linePath.points.length > 1) {
            const size = linePath.points.length;
            linePath.points.forEach((point, index) => {
                if (index < (size - 1)) {
                    lines.push([point, linePath.points[index + 1]])
                }
            });
        }
        return lines;
    }

}

export default class ConnectionRendererUtils {

    private static readonly CONNECTION_LINE_PATH_PROPERTY_NAME = "__connection-line-path__";

    public static insertAfterEventsPublisher(connection: IDiagramConnectionDto, node: SVGGElement) {
        const publisherNode = d3.select(ConnectionRendererUtils.createLinePointerEventsPublisherId(connection, true)).node() as SVGPathElement;
        const lineGroupNode = d3.select(ConnectionRendererUtils.createLineGroupId(connection, true)).node() as SVGGElement;
        if (publisherNode && lineGroupNode) {
            lineGroupNode.insertBefore(node, publisherNode);
        }
    }

    public static createLineGroupId(connection: IDiagramConnectionDto, selector?: boolean) {
        return (selector === true ? "#" : "") + "__connection-line-group-" + connection.identifier + "__";
    }

    public static createLineId(connection: IDiagramConnectionDto, selector?: boolean) {
        return (selector === true ? "#" : "") + "__connection-line-" + connection.identifier + "__";
    }

    public static createLinePointerEventsPublisherId(connection: IDiagramConnectionDto, selector?: boolean) {
        return ConnectionRendererUtils.createLineId(connection, selector) + "events__";
    }

    static createTextId(connection: IDiagramConnectionDto, selector?: boolean) {
        return (selector === true ? "#" : "") + "__connection-line-text-" + connection.identifier + "__";
    }

    static createTextShadowId(connection: IDiagramConnectionDto, selector?: boolean) {
        return (selector === true ? "#" : "") + "__connection-line-text-shadow-" + ConnectionRendererUtils.createTextId(connection) + "__";
    }

    public static createLinePath(connection: IDiagramConnectionDto,
                                 modelManager: ModelManager): LinePath | null {

        const sourceNode = modelManager.getDiagramNodeById(connection.sourceIdentifier);
        const targetNode = modelManager.getDiagramNodeById(connection.targetIdentifier);
        const sourceArea = Area.fromNode(sourceNode);
        const targetArea = Area.fromNode(targetNode);

        return this.createLinePathForAreas(sourceArea, targetArea, connection.bendpoints.map(point => new Point(point.x, point.y)));
    }

    public static createLinePathForAreas(sourceArea: Area,
                                         targetArea: Area,
                                         bendpoints: Point[]): LinePath | null {
        let linePoints: Array<Point> = [];
        if (sourceArea.isComplete() && targetArea.isComplete()) {
            const completeBendPoints = (bendpoints || [])
                .map(point => new Point(point.x, point.y))
                .filter(point => point.isComplete());
            linePoints = ConnectionRendererUtils.createLinePoints(sourceArea, targetArea, completeBendPoints);
        }
        let linePath: LinePath | null = null;
        const line = d3.line()(linePoints.map(point => point.toArray())) || null;  // empty string -> null
        if (line) {
            linePath = {
                path: line,
                points: linePoints,
            };
        }
        return linePath;
    }

    private static createLinePoints(sourceArea: Area,
                                    targetArea: Area,
                                    bendpoints: Array<Point>): Array<Point> {
        const points = [];
        points.push(ConnectionRendererUtils.getSourceStartPoint(sourceArea, targetArea, bendpoints));
        points.push(...bendpoints);
        points.push(ConnectionRendererUtils.getTargetEndPoint(sourceArea, targetArea, bendpoints));
        return points;
    }

    private static getSourceStartPoint(sourceArea: Area,
                                       targetArea: Area,
                                       bendpoints: Array<Point>): Point {
        let resolvedTargetArea: Area;
        if (bendpoints.length > 0) {
            const point = bendpoints[0];
            resolvedTargetArea = new Area(point.x, point.y, 0, 0);
        } else {
            resolvedTargetArea = targetArea;
        }

        return ConnectionRendererUtils.resolveSourceStartPoint(sourceArea, resolvedTargetArea);
    }

    private static getTargetEndPoint(sourceArea: Area,
                                     targetArea: Area,
                                     bendpoints: Array<Point>): Point {
        let resolvedSourceArea: Area;
        if (bendpoints.length > 0) {
            const point = bendpoints[bendpoints.length - 1];
            resolvedSourceArea = new Area(point.x, point.y, 0, 0);
        } else {
            resolvedSourceArea = sourceArea;
        }

        return ConnectionRendererUtils.resolveSourceStartPoint(targetArea, resolvedSourceArea);
    }

    private static resolveSourceStartPoint(sourceArea: Area,
                                           targetArea: Area): Point {
        const orientation = ConnectionRendererUtils.getSourceToTargetConnectionOrientation(sourceArea, targetArea);
        switch (orientation) {
            case ConnectionOrientation.LU:
                return new Point(sourceArea.x, sourceArea.y);
            case ConnectionOrientation.RU:
                return new Point(sourceArea.x + sourceArea.w, sourceArea.y);
            case ConnectionOrientation.LD:
                return new Point(sourceArea.x, sourceArea.y + sourceArea.h);
            case ConnectionOrientation.RD:
                return new Point(sourceArea.x + sourceArea.w, sourceArea.y + sourceArea.h);
            case ConnectionOrientation.U: {
                const xIntersection = ConnectionRendererUtils.getSourceTargetIntersection(sourceArea, targetArea, Axis.X) as Interval;
                const midNumber = xIntersection.getMidNumber();
                return new Point(midNumber, sourceArea.y);
            }
            case ConnectionOrientation.D: {
                const xIntersection = ConnectionRendererUtils.getSourceTargetIntersection(sourceArea, targetArea, Axis.X) as Interval;
                const midNumber = xIntersection.getMidNumber();
                return new Point(midNumber, sourceArea.y + sourceArea.h);
            }
            case ConnectionOrientation.L: {
                const yIntersection = ConnectionRendererUtils.getSourceTargetIntersection(sourceArea, targetArea, Axis.Y) as Interval;
                const midNumber = yIntersection.getMidNumber();
                return new Point(sourceArea.x, midNumber);
            }
            case ConnectionOrientation.R: {
                const yIntersection = ConnectionRendererUtils.getSourceTargetIntersection(sourceArea, targetArea, Axis.Y) as Interval;
                const midNumber = yIntersection.getMidNumber();
                return new Point(sourceArea.x + sourceArea.w, midNumber);
            }
        }
    }

    private static getSourceToTargetConnectionOrientation(
        sourceArea: Area,
        targetArea: Area,
    ): ConnectionOrientation {
        let orientation: ConnectionOrientation;

        if (sourceArea.contains(targetArea)) {
            // for now always DOWN
            orientation = ConnectionOrientation.D;
        } else if (targetArea.contains(sourceArea)) {
            // for now always UP
            orientation = ConnectionOrientation.U;
        } else {
            const xIntersection = ConnectionRendererUtils.getSourceTargetIntersection(sourceArea, targetArea, Axis.X);
            const yIntersection = ConnectionRendererUtils.getSourceTargetIntersection(sourceArea, targetArea, Axis.Y);

            if (xIntersection || yIntersection) {
                if (xIntersection) {
                    orientation = sourceArea.y < targetArea.y ? ConnectionOrientation.D : ConnectionOrientation.U;
                } else {
                    orientation = sourceArea.x < targetArea.x ? ConnectionOrientation.R : ConnectionOrientation.L;
                }
            } else {
                if (sourceArea.x < targetArea.x) {
                    orientation = sourceArea.y < targetArea.y ? ConnectionOrientation.RD : ConnectionOrientation.RU;
                } else {
                    orientation = sourceArea.y < targetArea.y ? ConnectionOrientation.LD : ConnectionOrientation.LU;
                }
            }
        }

        return orientation;
    }

    private static getSourceTargetIntersection(sourceArea: Area,
                                               targetArea: Area,
                                               axis: Axis): Interval | null {

        let intersection: Interval | null;

        if (axis === Axis.X) {
            const sourceXInterval = Interval.of(sourceArea.x, sourceArea.x + sourceArea.w);
            const targetXInterval = Interval.of(targetArea.x, targetArea.x + targetArea.w);

            intersection = ConnectionRendererUtils.getIntersection(sourceXInterval, targetXInterval);
        } else {
            const sourceYInterval = Interval.of(sourceArea.y, sourceArea.y + sourceArea.h);
            const targetYInterval = Interval.of(targetArea.y, targetArea.y + targetArea.h);

            intersection = ConnectionRendererUtils.getIntersection(sourceYInterval, targetYInterval);
        }

        return intersection;
    }

    static getIntersection(a: Interval, b: Interval): Interval | null {
        if (b.start > a.end || a.start > b.end) {
            return null;
        } else {
            return Interval.of(Math.max(a.start, b.start), Math.min(a.end, b.end))
        }
    }

    static createConnectionsGroupId(selector?: boolean) {
        return ChartGroup.getId(ChartGroupType.CONNECTIONS_GROUP, selector);
    }

    static getConnectionsGroup() {
        return ChartGroup.getSelection(ChartGroupType.CONNECTIONS_GROUP);
    }

    static createHiddenConnectionsGroupId(selector?: boolean) {
        return ChartGroup.getId(ChartGroupType.HIDDEN_CONNECTIONS_GROUP, selector);
    }

    static getHiddenConnectionsGroup() {
        return ChartGroup.getSelection(ChartGroupType.HIDDEN_CONNECTIONS_GROUP);
    }

    static createConnectionOrderGroupId(connection: IDiagramConnectionDto, selector?: boolean) {
        return (selector === true ? "#" : "") + "__connection-order-group-" + connection.identifier + "__";
    }

    static getConnectionOrderGroup(connection: IDiagramConnectionDto) {
        const id = ConnectionRendererUtils.createConnectionOrderGroupId(connection, true);
        return d3.select(id) as d3.Selection<SVGGElement, IDiagramConnectionDto, HTMLElement, undefined>;
    }

    static createHiddenConnectionOrderGroupId(connection: IDiagramConnectionDto, selector?: boolean) {
        return (selector === true ? "#" : "") + "__hidden-connection-order-group-" + connection.identifier + "__";
    }

    static getHiddenConnectionOrderGroup(connection: IDiagramConnectionDto) {
        const id = ConnectionRendererUtils.createHiddenConnectionOrderGroupId(connection, true);
        return d3.select(id) as d3.Selection<SVGGElement, IDiagramConnectionDto, HTMLElement, undefined>;
    }

    public static createLineColor(connection: IDiagramConnectionDto) {
        const rgba = connection.style?.lineColor;
        return ColorRenderer.renderColor(rgba, DEFAULT_LINE_COLOR);
    }

    public static createFontColor(connection: IDiagramConnectionDto) {
        const rgba = connection.style?.font?.color;
        return ColorRenderer.renderColor(rgba, DEFAULT_FONT_COLOR);
    }

    public static createFontSize(connection: IDiagramConnectionDto) {
        const size = connection.style?.font?.size;
        if (size) {
            return size as number;
        }
        return DEFAULT_FONT_SIZE as number;
    }

    public static createFontFamily(connection: IDiagramConnectionDto) {
        const fontName = connection.style?.font?.name;
        if (fontName) {
            return fontName + ", " + DEFAULT_FONT_FAMILY.join(", ");
        }
        return DEFAULT_FONT_FAMILY.join(", ");
    }

    public static createFontStyle(connection: IDiagramConnectionDto) {
        const styles = connection.style?.font?.styles;
        if (styles && styles.length > 0) {
            return styles.join(" ");
        }
        return DEFAULT_FONT_STYLE;
    }

    public static appendLinePathProperty(path: d3.Selection<SVGPathElement, IDiagramConnectionDto, null, undefined>,
                                         linePath: LinePath | null) {
        path.property(ConnectionRendererUtils.CONNECTION_LINE_PATH_PROPERTY_NAME, linePath);
    }

    public static extractLinePathProperty(connection: IDiagramConnectionDto) {
        const linePath = d3.select(ConnectionRendererUtils.createLineId(connection, true))
            .property(ConnectionRendererUtils.CONNECTION_LINE_PATH_PROPERTY_NAME) as LinePath;

        return linePath ? linePath : null;
    }

    static getConnectionTextClientBounds(connectionDto: IDiagramConnectionDto) {
        const connectionText = ConnectionRendererUtils.getConnectionText(connectionDto);
        if (!connectionText.empty()) {
            const rect = connectionText.node()?.getBoundingClientRect() as DOMRect;
            return new Area(rect.x, rect.y, rect.width, rect.height);
        } else {
            const nodeOrderGroup = ConnectionRendererUtils.getConnectionOrderGroupByConnectionId(connectionDto.identifier);
            const rect = nodeOrderGroup.node()?.getBoundingClientRect() as DOMRect;
            return new Area(rect.x + rect.width / 2 - CONNECTION_TEXT_WIDTH / 2, rect.y + rect.height / 2, CONNECTION_TEXT_WIDTH, rect.height);
        }
    }

    static getConnectionOrderGroupByConnectionId(connectionIdentifier: string) {
        const connectionGroupId = ConnectionRendererUtils.createConnectionOrderGroupIdByConnectionId(connectionIdentifier, true);
        return d3.select(connectionGroupId) as d3.Selection<SVGGElement, IDiagramConnectionDto, HTMLElement, undefined>;
    }

    static createConnectionOrderGroupIdByConnectionId(connectionIdentifier: string, selector?: boolean) {
        return (selector === true ? "#" : "") + "__connection-order-group-" + connectionIdentifier + "__";
    }

    private static getConnectionText(connectionDto: IDiagramConnectionDto) {
        return ConnectionRendererUtils.getConnectionOrderGroupByConnectionId(connectionDto.identifier)
            .select("." + TEXT_CLASS_NAME) as d3.Selection<SVGForeignObjectElement, IDiagramConnectionDto, HTMLElement, undefined>
    }

    static hideConnectionText(connectionDto: IDiagramConnectionDto) {
        ConnectionRendererUtils.getConnectionText(connectionDto)
            .style("visibility", "hidden");
    }

    static showConnectionText(connectionDto: IDiagramConnectionDto) {
        ConnectionRendererUtils.getConnectionText(connectionDto)
            .style("visibility", "visible");
    }

}
