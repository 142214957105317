import React, {useState} from "react";
import Grid from "../../../../../../../../components/dialogs/Grid";
import TextField from "../../../../../../../../components/fields/textfield/TextField";
import {Pending, RemoveCircle} from "@mui/icons-material";
import {_transl} from "../../../../../../../../store/localization/TranslMessasge";
import {HierarchyTreeTranslationKey} from "../../../../HierarchyTreeTranslationKey";
import {ActionButton} from "../../../../../../../../components/button/ActionButton";
import {ValidationResult} from "../../../../../../../../common/validation/ValidationResult";
import {QueriesSubformValidator} from "./QueriesSubformValidator";
import {QueryTextPrefillDialog} from "./QueryTextPrefillDialog";
import {Menu, MenuItem} from "@mui/material";
import Divider from "@mui/material/Divider";
import SettingsIcon from "@mui/icons-material/Settings";

export interface GraphQueryTreeLevelDefinition {
    name: string;
    queryText?: string;
    jumpIndex?: number;
}

export interface QueryLevelProps {
    levelIndex: number;
    levelDefinition: GraphQueryTreeLevelDefinition;
    onLevelRemoved: (levelIndex: number) => void;
    onLevelUpdated: (levelIndex: number, queryLevelData: GraphQueryTreeLevelDefinition) => void;
    numberOfLevels: number;
    validationResult?: ValidationResult;
}

export function QueryLevel({levelIndex, onLevelUpdated, onLevelRemoved, levelDefinition, validationResult, numberOfLevels}: QueryLevelProps) {
    const [prefillDialogOpened, setPrefillDialogOpened] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleActionClick = () => {
        onLevelUpdated(levelIndex, {...levelDefinition, queryText: "", jumpIndex: undefined});
        handleClose();
    };

    function findErrorForField(fieldId: string, validationResult?: ValidationResult) {
        if (validationResult) {
            const errors = validationResult.errors
                .filter(error => error.fieldId === `${levelIndex}.${fieldId}`);
            return errors.length > 0 ? errors[0] : undefined;
        }
        return undefined;
    }

    const nameError = findErrorForField(QueriesSubformValidator.NAME_FIELD_ID, validationResult);
    const queryTextError = findErrorForField(QueriesSubformValidator.QUERY_TEXT_FIELD_ID, validationResult);

    const handleOptionClick = (gotoIndex: number) => {
        onLevelUpdated(levelIndex, {...levelDefinition, queryText: undefined, jumpIndex: gotoIndex});
        handleClose();
    };

    const optionsArray = Array.from({length: numberOfLevels}, (_, idx) => idx + 1)
        .filter(level => level !== levelIndex + 1 && level !== levelDefinition.jumpIndex);

    return (
        <>
            {prefillDialogOpened &&
                <QueryTextPrefillDialog
                    onClose={() => setPrefillDialogOpened(false)}
                    onPrefill={(queryText: string) => {
                        onLevelUpdated(levelIndex, {...levelDefinition, queryText: queryText, jumpIndex: undefined});
                    }}
                />
            }

            <Grid container spacing={2}>
                <Grid item xs sm={4}>
                    <Grid container spacing={2} noStyle>
                        <Grid item xs={"auto"}>
                            <ActionButton id={"query-level-delete-action-button"}
                                          color={"error"}
                                          onClick={() => onLevelRemoved(levelIndex)}
                                          style={{paddingLeft: 0, paddingRight: 0}}
                                          disabled={levelIndex === 0}
                                          tooltip={_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_DELETE)}
                                          icon={<RemoveCircle/>}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                label={(levelIndex + 1) + ". " + _transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL)}
                                value={levelDefinition.name}
                                required={true}
                                onChange={(name) => onLevelUpdated(levelIndex, {...levelDefinition, name})}
                                errorMessage={nameError ? _transl(nameError.errorMessage) : undefined}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm>
                    {levelDefinition.queryText != null &&
                        <TextField
                            label={_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_QUERY)}
                            multiline
                            rows={4}
                            value={levelDefinition.queryText}
                            required={true}
                            onChange={(queryText) => onLevelUpdated(levelIndex, {...levelDefinition, queryText})}
                            errorMessage={queryTextError ? _transl(queryTextError.errorMessage) : undefined}
                        />}
                    {levelDefinition.queryText == null &&
                        <p>{_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_JUMP, {LEVEL_INDEX: levelDefinition.jumpIndex})}</p>
                    }
                </Grid>
                <Grid item xs={12} sm={"auto"} textAlign={"right"}>
                    <>
                        <ActionButton
                            id={"query-level-query-jump-switch-action-button"}
                            onClick={handleIconClick}
                            color={"default"}
                            disabled={optionsArray.length === 0 && levelDefinition.queryText != null}
                            icon={<SettingsIcon/>}
                            tooltip={_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_QUERY_JUMP_SWITCH)}/>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}>
                            {levelDefinition.queryText == null &&
                                <MenuItem onClick={handleActionClick}>
                                    {_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_QUERY)}
                                </MenuItem>
                            }
                            {optionsArray.length > 0 && levelDefinition.queryText == null &&
                                <Divider/>
                            }
                            {optionsArray.map((num: number) => (
                                <MenuItem key={num} onClick={() => {
                                    handleOptionClick(num);
                                }}>
                                    {_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_JUMP, {LEVEL_INDEX: num})}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                </Grid>
                <Grid item xs={12} sm={"auto"} textAlign={"right"}>
                    <ActionButton id={"query-level-prefill-action-button"}
                                  color={"default"}
                                  onClick={() => {
                                      setPrefillDialogOpened(true)
                                  }}
                                  style={{paddingLeft: 0, paddingRight: 0}}
                                  icon={<Pending/>}
                                  tooltip={_transl(HierarchyTreeTranslationKey.SUBFORM_QUERIES_LEVEL_QUERY_PREFILL)}
                    />
                </Grid>
            </Grid>
        </>
    );
}
