import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Dialog, DialogContent, IconButton, Paper} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CommonCssStyles from "../../../../css/CommonCssStyles";
import RenderMode from "../../../../common/diagrameditor/context/RenderMode";
import DiagramEditorComponent from "../../../../components/diagrameditor/DiagramEditorComponent";
import IDiagramEditorApi from "../../../../common/diagrameditor/api/IDiagramEditorApi";
import IDiagramApi from "../../../../common/diagrameditor/api/IDiagramApi";
import {IModelDataExportDto} from "../../../../common/apis/Exports";
import Api from "../../../../common/Api";
import {Observable} from "rxjs";
import clsx from "clsx";
import {hideMainPageOverlay, MAIN_PAGE_OVERLAY_ZINDEX} from "../../../MainPage";
import * as d3 from "d3";
import ConfirmationDialog from "../../../../components/dialogs/ConfirmationDialog";
import CloseIcon from "@mui/icons-material/Close";
import {Area, Point} from "../../../../common/diagrameditor/util/GeometryUtils";
import EditableTextField from "../../../../components/fields/EditableTextField";
import {UpdateResponse} from "../../../../components/fields/EditableComponent";
import ConnectionTypeSelectionDialog from "./diagrameditor/ConnectionTypeSelectionDialog";
import {NewConnectionDefinition} from "../../../../common/diagrameditor/manager/ConnectionCreateManager";
import RemoveObjectsConfirmationDialog from "./diagrameditor/RemoveObjectsConfirmationDialog";
import NestingConnectionCreationDialog from "./diagrameditor/NestingConnectionCreationDialog";
import NodeContextMenu from "./diagrameditor/NodeContextMenu";
import ElementDetailDialog from "../elementdetail/ElementDetailDialog";
import {IDiagramNodeDto} from "../../../../common/apis/diagram/IDiagramNodeDto";
import AlertDialog, {AlertDialogType} from "../../../../components/dialogs/AlertDialog";
import {ObjectType} from "../../../../common/apis/editor/ObjectType";
import {IDiagramConnectionDto} from "../../../../common/apis/diagram/IDiagramConnectionDto";
import {RelationshipDto} from "../../../../common/apis/relationship/RelationshipDto";
import {IModelDto} from "../../../../common/apis/model/IModelDto";
import {IEditMode} from "../../../../common/diagrameditor/editor/IEditMode";
import {IMode} from "../../../../common/diagrameditor/model/IMode";
import ConnectionContextMenu from "./diagrameditor/ConnectionContextMenu";
import {_transl} from "../../../../store/localization/TranslMessasge";
import {DiagramTranslationKey} from "./DiagramTranslationKey";
import {StyleSettings} from "../../../../diagram/editor/style/StyleSettings";
import {
    StyleSettingsDialog,
    StyleSettingsDialogProps
} from "../../../../diagram/editor/style/dialog/StyleSettingsDialog";
import {Styleable} from "../../../../common/apis/diagram/Styleable";
import {DiagramInfoDto} from "../../../../common/apis/diagram/DiagramInfoDto";
import {useDiagramBackupService} from "../../../../common/diagrameditor/backup/provider/DiagramBackupProvider";
import {IPreEditMode} from "../../../../common/diagrameditor/editor/IPreEditMode";
import ElementListDialog from "../elements/ElementListDialog";
import {ElementDto} from "../../../../common/apis/element/ElementDto";
import elementsService from "../elements/service/ElementService";
import importsService from "../../../../common/apis/ImportsService";
import {ArchimateElement} from "../../../../common/archimate/ArchimateElement";
import {ArchimateRelationship} from "../../../../common/archimate/ArchimateRelationship";
import {useSelector} from "react-redux";
import {IApplicationState} from "../../../../store/Store";
import {TooltippedIconButton} from "../../../../components/button/TooltippedIconButton";
import {ChatIconButton} from "./diagrameditor/iconbutton/ChatIconButton";
import {ErrorTranslationKey} from "../ErrorTranslationKey";
import chatService, {ChatState} from "../../../../common/apis/chat/ChatService";
import Snackbar from "../snackbar/Snackbar";
import PaperContextMenu from "./diagrameditor/PaperContextMenu";
import EditIcon from "@mui/icons-material/Edit";
import {CommonTranslation} from "../CommonTranslation";
import EventManagerContext from "../../../../common/event/EventManagerContext";
import {ChangeChatLayerVisibilityEvent, ChatEventType, ChatLayerVisibilityChangedEvent} from "../chat/ChatEvents";
import {ChatCoordinatesManager} from "../chat/ChatCoordinatesManager";
import EventManager from "../../../../common/event/EventManager";
import {
    DiagramEditorEventType,
    ModalWindowVisibilityChangedEvent
} from "../../../../common/event/diagrameditor/DiagramEditorEvents";
import {ClipboardEventType, ItemsInfo, PasteItemsFromClipboardDuplicatesEvent} from "./diagrameditor/ClipboardEvents";
import PasteDuplicateItemsDialog from "./diagrameditor/PasteDuplicateItemsDialog";
import {ID_PARAM} from "../../../../common/routes/AppRoutes";
import {
    EventType,
    NewNodeShowUpdateLabelEvent,
    ShowNodesNestingConnectionCreationDialogEvent
} from "../../../../common/event/Event";
import {ValidationError} from "../../../../common/ValidationError";
import {ImportErrorCode} from "../import/ImportErrorCode";
import ConcurrentUpdateDialog from "../../../../common/diagrameditor/ConcurrentUpdateDialog";
import CopyOfModelDialog from "../../../../common/diagrameditor/CopyOfModelDialog";
import {KeycloakHolder} from "../../../../keycloak/KeycloakHolder";
import {IntervalTokenRefreshService} from "../../../../common/TokenRefreshService";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: CommonCssStyles.getRootPageStyles(theme, {
            height: "94vh",
            display: "flex",
            flexDirection: "column",
            padding: 0,
            margin: 0
        }),
        headerPageSegment: CommonCssStyles.getHeaderPageSegmentStyles(theme),
        controlPageSegment: {
            position: "relative",
            flexGrow: 1,
            marginBottom: "10px",
        },
        pageMenu: {
            display: "flex",
            alignItems: "center",
            gap: "15px",
        },
        pageTitle: {
            display: "flex",
            userSelect: "none",
        },
        pageMenuButtonsMenu: {
            flexGrow: 1,
            "& > div": {
                display: "flex",
            }
        },
        pageMenuButtonsLeftMenu: {
            "& > *": {
                padding: 5
            }

        },
        pageMenuButtonsCenterMenu: {
            flexGrow: 1,
        },
        pageMenuButtonsRightMenu: {
            "& > *": {
                width: "100",
            }
        },
        nodeLabelUpdateDiv: {
            position: "sticky",
            zIndex: MAIN_PAGE_OVERLAY_ZINDEX + 1000,
            backgroundColor: "white",
        },
        connectionLabelUpdateDiv: {
            position: "sticky",
            zIndex: MAIN_PAGE_OVERLAY_ZINDEX + 1000,
            backgroundColor: "white",
        }
    })
);

export const DIAGRAM_EDITOR_PAGE_ID = "__diagram-editor-page__";
const NODE_LABEL_UPDATE_DIV_ID = "__diagram-editor-node-label-update-div__";
const CONNECTION_LABEL_UPDATE_DIV_ID = "__diagram-editor-connection-label-update-div__";

// Component Props

interface IProps {
    // router props
    [ID_PARAM]: string,
    onClosed: () => void,
    mode?: RenderMode.PRE_EDIT | RenderMode.EDIT,
}

// Component state

export enum SaveButtonStatus {
    SAVE_NOT_NEEDED,
    SAVE_NEEDED,
    SAVE_IN_PROGRESS,
    SAVE_FAILED,
}

export enum SelectionMode {
    UNSELECTED,
    SINGLE,
    MULTIPLE,
}
interface INodeLabelUpdateInfo {
    label: string,
    clientBounds: Area,
    isMultiRow: boolean,
    doUpdate: (label: string) => Observable<UpdateResponse>,
    doCancel: () => void,
}

interface IConnectionLabelUpdateInfo {
    label: string,
    clientBounds: Area,
    doUpdate: (label: string) => Observable<UpdateResponse>,
    doCancel: () => void,
}

interface IConnectionTypeDialogInfo {
    sourceType: ArchimateElement,
    targetType: ArchimateElement,
    allowedRelationshipTypes: ArchimateRelationship[],
    hiddenRelationships: RelationshipDto[],
    eventPoint: Point,
    onCreate: (definition: NewConnectionDefinition, event: any) => void,
    onCancel: (event: any) => void,
}

interface IConfirmationDialog {
    title: string,
    text: string,
    confirmCallback: (event: any) => void,
    cancelCallback: ((event: any) => void),
    isModal: boolean,
}

interface RemoveObjectsConfirmationDialogSettings {
    disableRemoveFromModel: boolean;
    confirmCallback: (event: any, removeFromModel: boolean) => void;
    cancelCallback: ((event: any) => void);
}

interface INestingConnectionCreationDialog {
    parentCandidate: IDiagramNodeDto,
    nodesToConnect: Array<IDiagramNodeDto>,
    isNewNode: boolean,
    isUndoRedoResult?: boolean,
    elementCreated?: boolean,
    renderedNodesCount?: number,
    selectedNodes: Array<IDiagramNodeDto>,
    movedNodes: Array<IDiagramNodeDto>,
    selectedConnections: Array<IDiagramConnectionDto>,
    dimensions: { [id: string]: Area }
}

interface NodeContextMenuDialog {
    node: IDiagramNodeDto,
    selectedNodes: IDiagramNodeDto[],
    selectedConnections: IDiagramConnectionDto[],
    clientCoordinates: [number, number],
    transformedClientCoordinates: [number, number],
}

interface PasteDuplicateItems {
    itemsInfo: ItemsInfo,
}

interface ConnectionContextMenuDialog {
    connection: IDiagramConnectionDto,
    selectedNodes: IDiagramNodeDto[],
    selectedConnections: IDiagramConnectionDto[],
    clientCoordinates: [number, number],
}

interface PaperContextMenuDialog {
    clientCoordinates: [number, number],
    transformedClientCoordinates: [number, number],
}

interface ElementDetailDialogInfo {
    elementId: string,
}

interface ElementsGridDialogInfo {
    elements: ElementDto[],
}

interface ErrorDialogInfo {
    text: string,
}

interface CopyOfModelDialogInfo {
    oldDiagramIdentifier: string,
}

export const UPDATE_BACKUP_DATE_INTERVAL_IN_MS = 2000;

export default function DiagramEditorDialog(props: IProps) {
    const classes = useStyles();
    const diagramId = props.id;
    const diagramBackupService = useDiagramBackupService();
    const initialMode = props.mode ?? RenderMode.PRE_EDIT;
    const user = useSelector((application: IApplicationState) => application.user.userData);
    const chatCoordinatesManager = useRef(new ChatCoordinatesManager());

    // refs
    const diagramApi = useRef<IDiagramApi>(createDiagramApi());
    const diagramEditorApi = useRef<IDiagramEditorApi | undefined>();

    // callbacks
    const fetchDiagram = useCallback(() => fetchEditedDiagram(diagramId), [diagramId]);

    function hasUserAtLeastCtenarPermissionForAllElements(model: IModelDto): boolean {
        for (const element of model.graph.elements) {
            if (!element.acl.canRead && !element.acl.canUpdate) {
                return false;
            }
        }
        return true;
    }

    const showPromptBeforeUnloadCallback = useCallback((event: any) => showPromptBeforeUnload(event), []);

    const createPreEditMode = useCallback((): IPreEditMode => {
        return {
            mode: RenderMode.PRE_EDIT,
            diagramApi: diagramApi.current,
            onDiagramEditorApiCreated: setupDiagramEditorApi,
        };
    }, []);

    // state
    const [diagramName, setDiagramName] = useState<String>(props.id);
    const [mode, setMode] = useState<IMode>(initialMode === RenderMode.PRE_EDIT ? createPreEditMode() : createEditMode());
    const [saveButtonStatus, setSaveButtonStatus] = useState<SaveButtonStatus>(SaveButtonStatus.SAVE_NOT_NEEDED);
    const [showSaveChangesDialog, setShowSaveChangesDialog] = useState<boolean>(false);
    const [nodeLabelUpdateInfo, setNodeLabelUpdateInfo] = useState<INodeLabelUpdateInfo | undefined>();
    const [connectionLabelUpdateInfo, setConnectionLabelUpdateInfo] = useState<IConnectionLabelUpdateInfo | undefined>();
    const [connectionTypeDialogInfo, setConnectionTypeDialogInfo] = useState<IConnectionTypeDialogInfo | undefined>();
    const [confirmationDialog, setConfirmationDialog] = useState<IConfirmationDialog | undefined>();
    const [hasUserSufficientElementPermissionsAlert, setHasUserSufficientElementPermissionsAlert] = useState<boolean>(false);
    const [removeItemsDialogSettings, setRemoveItemsDialogSettings] = useState<RemoveObjectsConfirmationDialogSettings | undefined>();
    const [nestingConnectionCreationDialog, setNestingConnectionCreationDialog] = useState<INestingConnectionCreationDialog | undefined>();
    const [nodeContextMenuDialog, setNodeContextMenuDialog] = useState<NodeContextMenuDialog | undefined>();
    const [connectionContextMenuDialog, setConnectionContextMenuDialog] = useState<ConnectionContextMenuDialog | undefined>();
    const [elementDetailDialogInfo, setElementDetailDialogInfo] = useState<ElementDetailDialogInfo | undefined>();
    const [elementsGridDialogInfo, setElementsGridDialogInfo] = useState<ElementsGridDialogInfo | undefined>();
    const [errorDialogInfo, setErrorDialogInfo] = useState<ErrorDialogInfo | undefined>();
    const [concurrentUpdateDialogOpened, setConcurrentUpdateDialogOpened] = useState<boolean>(false);
    const [copyOfModelDialog, setCopyOfModelDialog] = useState<CopyOfModelDialogInfo | undefined>();
    const [styleSettingsDialogState, setStyleSettingsDialogState] = useState<StyleSettingsDialogProps>({open: false});
    const [isUserPermittedToEdit, setIsUserPermittedToEdit] = useState<boolean | undefined>();
    const [paperContextMenuDialog, setPaperContextMenuDialog] = useState<PaperContextMenuDialog | undefined>();
    const [pasteDuplicateItemsDialog, setPasteDuplicateItemsDialog] = useState<PasteDuplicateItems | undefined>();

    const [chatLayerVisible, setChatLayerVisible] = useState<boolean>(false);
    const [numberOfUnresolvedChats, setNumberOfUnresolvedChats] = useState<number>(0);
    const [refreshChatCount, setRefreshChatCount] = useState<Date>(new Date());

    const onDiagramModelFetched = useCallback((model: IModelDto) => {
        if (mode.mode === RenderMode.EDIT) {
            setHasUserSufficientElementPermissionsAlert(!hasUserAtLeastCtenarPermissionForAllElements(model));
        }
        setDiagramName(model.diagrams[0].diagramInfo.name as string);
    }, [mode]);

    const eventManager = useContext(EventManagerContext);

    const publishDialogVisibilityEvent = (eventManager: EventManager, isDialogVisible: boolean) => {
        const visibilityChangedEvent: ModalWindowVisibilityChangedEvent = {
            type: DiagramEditorEventType.MODAL_WINDOW_VISIBILITY_CHANGED,
            isVisible: isDialogVisible
        };
        eventManager.publishEvent(visibilityChangedEvent);
    };

    // effects
    useEffect(() => {
        return () => hideMainPageOverlay();
    }, []);

    const switchToPreEditMode = useCallback(() => {
        if (mode.mode === RenderMode.EDIT) {
            setMode(createPreEditMode());
        }
    }, [mode.mode, createPreEditMode]);

    useEffect(() => {
        let isUnmounted = false;

        async function fetchDiagramInfo() {
            const response = await Api.diagrams.doSearch({identifiers: diagramId}).toPromise();
            const diagrams = response.response as Array<DiagramInfoDto>;
            return diagrams.length > 0 ? diagrams[0] : null;
        }

        (async () => {
            const diagramInfo = await fetchDiagramInfo();
            if (!isUnmounted && isUserPermittedToEdit === undefined) {
                if (diagramInfo?.acl?.canUpdate) {
                    setIsUserPermittedToEdit(true);
                } else {
                    switchToPreEditMode();
                }
            }
        })();
        return () => {
            isUnmounted = true;
        }
    }, [diagramId, switchToPreEditMode, isUserPermittedToEdit]);

    useEffect(() => {
        if (nodeLabelUpdateInfo) {
            const updateDivSelection = d3.select("#" + NODE_LABEL_UPDATE_DIV_ID + " input ");
            if (updateDivSelection.size() === 1) {
                setTimeout(() => (updateDivSelection.node() as HTMLInputElement).click(), 0);
            }
        }
    }, [nodeLabelUpdateInfo]);

    useEffect(() => {
        if (connectionLabelUpdateInfo) {
            const updateDivSelection = d3.select("#" + CONNECTION_LABEL_UPDATE_DIV_ID + " input ");
            if (updateDivSelection.size() === 1) {
                setTimeout(() => (updateDivSelection.node() as HTMLInputElement).click(), 0);
            }
        }
    }, [connectionLabelUpdateInfo]);

    useEffect(() => {
        chatService.countChatsByFilter({diagramId: diagramId, state: ChatState.UNRESOLVED, containsChatNode: true})
            .then(count => setNumberOfUnresolvedChats(count))
            .catch(err => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_LOAD_DATA)));
    }, [diagramId, refreshChatCount]);

    useEffect(() => {
        if (saveButtonStatus === SaveButtonStatus.SAVE_NEEDED) {
            const interval = setInterval(() => {
                diagramBackupService.updateBackupDate(diagramId, new Date());
            }, UPDATE_BACKUP_DATE_INTERVAL_IN_MS);
            return () => clearInterval(interval);
        }
    }, [saveButtonStatus, diagramId, diagramBackupService]);

    function onChatsUpdated() {
        setRefreshChatCount(new Date());
    }

    useEffect(() => {
        var unsubscribe = eventManager.subscribeListener(ChatEventType.CHAT_LAYER_VISIBILITY_CHANGED, (event: ChatLayerVisibilityChangedEvent) => {
            setChatLayerVisible(event.chatLayerVisible);
        });
        return () => unsubscribe();
    }, [eventManager]);

    useEffect(() => {
        var unsubscribe = eventManager.subscribeListener(EventType.SHOW_NODES_NESTING_CONNECTION_CREATION_DIALOG, (event: ShowNodesNestingConnectionCreationDialogEvent) => {
            setNestingConnectionCreationDialog({
                parentCandidate: event.parentCandidate,
                nodesToConnect: event.nodesToConnectWithParent,
                isNewNode: event.isNewNode,
                isUndoRedoResult: event.isUndoRedoResult,
                elementCreated: event.elementCreated,
                renderedNodesCount: event.renderedNodesCount,
                selectedNodes: event.selectedNodes,
                movedNodes: event.movedNodes,
                selectedConnections: event.selectedConnections,
                dimensions: event.dimensions
            })
        });
        return () => unsubscribe();
    }, [eventManager]);

    useEffect(() => {
        var unsubscribe = eventManager.subscribeListener(ClipboardEventType.PASTE_ITEMS_FROM_CLIPBOARD_DUPLICATES_WARNING, (event: PasteItemsFromClipboardDuplicatesEvent) => {
            setPasteDuplicateItemsDialog({
                itemsInfo: event.itemsInfo
            });
        });
        return () => unsubscribe();
    }, [eventManager]);

    useEffect(() => {
        publishDialogVisibilityEvent(eventManager, elementDetailDialogInfo !== undefined);
    }, [eventManager, elementDetailDialogInfo]);

    useEffect(() => {
        publishDialogVisibilityEvent(eventManager, elementsGridDialogInfo !== undefined);
    }, [eventManager, elementsGridDialogInfo]);

    useEffect(() => {
        const disableScroll = (e: WheelEvent) => {
            e.preventDefault();
        };
        if (nodeLabelUpdateInfo || connectionLabelUpdateInfo) {
            document.addEventListener("wheel", disableScroll, {passive: false});
        }
        return () => {
            document.removeEventListener("wheel", disableScroll);
        };
    }, [nodeLabelUpdateInfo, connectionLabelUpdateInfo]);

    function switchChatLayerVisibility(visible: boolean) {
        const event: ChangeChatLayerVisibilityEvent = {
            type: ChatEventType.CHANGE_CHAT_LAYER_VISIBILITY,
            chatLayerVisible: visible
        };
        eventManager.publishEvent(event);
    }

    function createEditMode(): IEditMode {
        return {
            mode: RenderMode.EDIT,
            diagramApi: diagramApi.current,
            onDiagramEditorApiCreated: setupDiagramEditorApi,
        }
    }

    function setupDiagramEditorApi(diagramEditor: IDiagramEditorApi) {
        diagramEditorApi.current = diagramEditor;
    }

    function createDiagramApi(): IDiagramApi {
        return {
            showElementDetail: (elementId: string) => {
            },
            notifySaveStatusUpdated: (saveNeeded: boolean) =>
                notifySaveStatusUpdated(saveNeeded),
            saveChanges: (event: any) =>
                saveChanges(event),
            getSaveButtonStatus: () => saveButtonStatus,
            updateNodeLabel: (nodeLabel: string, nodeClientBounds: Area, isMultiRow: boolean, doUpdate: (label: string) => Observable<UpdateResponse>, doCancel: () => void) =>
                showNodeLabelUpdateUI(nodeLabel, nodeClientBounds, isMultiRow, doUpdate, doCancel),
            updateConnectionLabel: (connectionLabel: string, connectionClientBounds: Area, doUpdate: (label: string) => Observable<UpdateResponse>, doCancel: () => void) =>
                showConnectionLabelUpdateUI(connectionLabel, connectionClientBounds, doUpdate, doCancel),
            generateIdentifiers: (objectTypes: ObjectType[], successCallback: (ids: string[]) => void, errorCallback: (error: any) => void) =>
                generateIdentifiers(objectTypes, successCallback, errorCallback),
            showConnectionTypeSelectionDialog: (sourceType: ArchimateElement | undefined, targetType: ArchimateElement | undefined,
                                                allowedRelationshipTypes: ArchimateRelationship[], hiddenRelationships: RelationshipDto[],
                                                point: Point, onCreate: (definition: NewConnectionDefinition, event: any) => void, onCancel: (event: any) => void) =>
                showConnectionTypeSelectionDialog(sourceType, targetType, allowedRelationshipTypes, hiddenRelationships, point, onCreate, onCancel),
            showConfirmationDialog: (title: string, text: string, confirmCallback: (event: any) => void, cancelCallback: (event: any) => void, isModal: boolean) =>
                showConfirmationDialog(title, text, confirmCallback, cancelCallback, isModal),
            showRemoveObjectsDialog: (disableRemoveFromModel: boolean, confirmCallback: (event: any, removeElementsOrRelationships: boolean) => void, cancelCallback: (event: any) => void) =>
                showRemoveObjectsDialog(disableRemoveFromModel, confirmCallback, cancelCallback),
            showNodeContextMenu: (node: IDiagramNodeDto, selectedNodes: IDiagramNodeDto[], selectedConnections: IDiagramConnectionDto[], clientCoordinates: [number, number], transformedClientCoordinates: [number, number]) => showNodeContextMenu(node, selectedNodes, selectedConnections, clientCoordinates, transformedClientCoordinates),
            showPaperContextMenu: (clientCoordinates, transformedClientCoordinates: [number, number]) => showPaperContextMenu(clientCoordinates, transformedClientCoordinates),
            showElementDetailDialog: (elementIdentifier: string) => showElementDetailDialog(elementIdentifier),
            showElementsGridDialog: (elementIdentifiers: string[]) => showElementsGridDialog(elementIdentifiers),
            showErrorDialog: (text: string) => setErrorDialogInfo({text: text}),
            showConnectionContextMenu: (connection: IDiagramConnectionDto, selectedNodes: IDiagramNodeDto[], selectedConnections: IDiagramConnectionDto[], clientCoordinates: [number, number]) => showConnectionContextMenu(connection, selectedNodes, selectedConnections, clientCoordinates),
        }
    }

    function switchToEditMode() {
        setMode(createEditMode());
    }

    function closeDialog() {
        const containsUnsavedChanges = saveButtonStatus !== SaveButtonStatus.SAVE_NOT_NEEDED;

        if (!containsUnsavedChanges) {
            props.onClosed();
        } else {
            setShowSaveChangesDialog(true);
        }
    }

    async function cancelChanges() {
        await chatCoordinatesManager.current.fixAllChatCoordinatesForDiagram(diagramId);
        diagramBackupService.removeBackup(diagramId);
        unregisterBeforeUnloadListener(showPromptBeforeUnloadCallback);
        props.onClosed();
    }

    function saveChanges(event: any, closeDialogAfterSuccessfulSave?: boolean) {
        const model = diagramEditorApi.current!.getModel();
        setSaveButtonStatus(SaveButtonStatus.SAVE_IN_PROGRESS);
        importsService.importModelJSON(model, user!, () => onSuccess(event, model, closeDialogAfterSuccessfulSave),
            (error: any) => {
                setSaveButtonStatus(SaveButtonStatus.SAVE_FAILED);
                if (error instanceof ValidationError) {
                    const validationError = error as ValidationError;
                    if (validationError.error.code === ImportErrorCode.CONFLICTING_VERSION) {
                        setConcurrentUpdateDialogOpened(true);
                    }
                }
            });
    }

    function onSuccess(event: any, model: IModelDto, closeDialogAfterSuccessfulSave?: boolean) {
        setSaveButtonStatus(SaveButtonStatus.SAVE_NOT_NEEDED);
        diagramEditorApi.current?.diagramSaved(event);
        diagramBackupService.removeBackup(model.diagrams[0].diagramInfo.identifier);
        unregisterBeforeUnloadListener(showPromptBeforeUnloadCallback);
        if (closeDialogAfterSuccessfulSave) {
            setTimeout(() => props.onClosed(), 500);
        } else {
            fetchDiagram().toPromise()
                .then(value => {
                    diagramEditorApi.current!.getModelAccessor().setUpdatedToDiagram(value.diagrams[0].diagramInfo.updated);
                });
        }
    }

    function notifySaveStatusUpdated(saveNeeded: boolean) {
        const newState = saveNeeded ? SaveButtonStatus.SAVE_NEEDED : SaveButtonStatus.SAVE_NOT_NEEDED;
        setSaveButtonStatus(newState);
        handleBeforeUnloadListener(newState, showPromptBeforeUnloadCallback);
    }

    function handleBeforeUnloadListener(newState: SaveButtonStatus,
                                        beforeUnloadListener: (event: any) => boolean) {
        unregisterBeforeUnloadListener(beforeUnloadListener);
        if (newState === SaveButtonStatus.SAVE_NEEDED) {
            registrBeforeUnloadListener(beforeUnloadListener);
        }
    }

    function registrBeforeUnloadListener(beforeUnloadListener: (event: any) => boolean) {
        window.addEventListener("beforeunload", beforeUnloadListener);
    }

    function unregisterBeforeUnloadListener(beforeUnloadListener: (event: any) => boolean) {
        window.removeEventListener("beforeunload", beforeUnloadListener);
    }

    function showPromptBeforeUnload(event: any): boolean {
        if (IntervalTokenRefreshService.getNextRefreshTimeoutMs(KeycloakHolder.getParsedToken()!) < 0) {
            return false;
        }
        event.returnValue = true;
        return true;
    }

    function showNodeLabelUpdateUI(nodeLabel: string, nodeClientBounds: Area, isMultiRow: boolean, doUpdate: (label: string) => Observable<UpdateResponse>, doCancel: () => void) {
        setNodeLabelUpdateInfo({
            label: nodeLabel,
            clientBounds: nodeClientBounds,
            isMultiRow: isMultiRow,
            doUpdate: doUpdate,
            doCancel: doCancel,
        });
    }

    function showConnectionLabelUpdateUI(connectionLabel: string, connectionClientBounds: Area, doUpdate: (label: string) => Observable<UpdateResponse>, doCancel: () => void) {
        setConnectionLabelUpdateInfo({
            label: connectionLabel,
            clientBounds: connectionClientBounds,
            doUpdate: doUpdate,
            doCancel: doCancel,
        });
    }

    function generateIdentifiers(objectTypes: ObjectType[], successCallback: (ids: string[]) => void, errorCallback: (error: any) => void) {
        Api.editor.generateIdentifiersUsingCallbacks(objectTypes, successCallback, errorCallback);
    }

    function showConnectionTypeSelectionDialog(sourceType: ArchimateElement | undefined, targetType: ArchimateElement | undefined,
                                               allowedRelationshipTypes: ArchimateRelationship[],
                                               hiddenRelationships: RelationshipDto[], point: Point,
                                               onCreate: (definition: NewConnectionDefinition, event: any) => void,
                                               onCancel: (event: any) => void) {
        if (sourceType && targetType) {
            setConnectionTypeDialogInfo({
                sourceType: sourceType,
                targetType: targetType,
                allowedRelationshipTypes: allowedRelationshipTypes,
                hiddenRelationships: hiddenRelationships,
                eventPoint: point,
                onCreate: onCreate,
                onCancel: onCancel,
            });
        } else {
            Api.editor.generateIdentifier(ObjectType.CONNECTION)
                .subscribe({
                    next: (response) => {
                        onCreate({connectionIdentifier: response.response}, {});
                    },
                    error: () => {
                    },
                })
        }
    }

    function showConfirmationDialog(title: string,
                                    text: string,
                                    confirmCallback: (event: any) => void,
                                    cancelCallback: (event: any) => void,
                                    isModal: boolean) {
        setConfirmationDialog({
            title: title,
            text: text,
            confirmCallback: confirmCallback,
            cancelCallback: cancelCallback,
            isModal: isModal,
        })
    }

    function showRemoveObjectsDialog(disableRemoveFromModel: boolean, confirmCallback: (event: any, removeFromModel: boolean) => void, cancelCallback: (event: any) => void) {
        setRemoveItemsDialogSettings({
            disableRemoveFromModel: disableRemoveFromModel,
            confirmCallback: confirmCallback,
            cancelCallback: cancelCallback,
        })
    }

    function showNodeContextMenu(node: IDiagramNodeDto, selectedNodes: IDiagramNodeDto[], selectedConnections: IDiagramConnectionDto[], clientCoordinates: [number, number],
                                 transformedClientCoordinates: [number, number]) {
        setNodeContextMenuDialog({
            node: node,
            selectedNodes: selectedNodes,
            selectedConnections: selectedConnections,
            clientCoordinates: clientCoordinates,
            transformedClientCoordinates: transformedClientCoordinates,
        })
    }

    function showConnectionContextMenu(connection: IDiagramConnectionDto, selectedNodes: IDiagramNodeDto[], selectedConnections: IDiagramConnectionDto[], clientCoordinates: [number, number]) {
        setConnectionContextMenuDialog({
            connection: connection,
            selectedNodes: selectedNodes,
            selectedConnections: selectedConnections,
            clientCoordinates: clientCoordinates,
        })
    }

    function showPaperContextMenu(clientCoordinates: [number, number], transformedClientCoordinates: [number, number]) {
        setPaperContextMenuDialog({
            clientCoordinates: clientCoordinates,
            transformedClientCoordinates: transformedClientCoordinates,
        })
    }

    function showElementDetailDialog(elementId: string | undefined) {
        if (elementId) {
            setElementDetailDialogInfo({
                elementId: elementId,
            })
        } else {
            setElementDetailDialogInfo(undefined);
        }
    }

    function showElementsGridDialog(elementIds: string[]) {
        if (elementIds.length > 0) {
            elementsService.doSearch(elementIds)
                .then(response => {
                    const elementDtos = response.items.map((item) => item)
                    setElementsGridDialogInfo({elements: elementDtos});
                })
                .catch((error) => {
                });
        }
    }

    function showStyleSettingsDialog(styleable: Styleable) {
        if (diagramEditorApi.current) {
            const {
                settings,
                variant,
                mode
            } = diagramEditorApi.current.getStyleManager().extractStyleSettingsDialogConfig(styleable);
            setStyleSettingsDialogState({
                open: true,
                variant: variant,
                settings: settings,
                mode: mode
            });
        }
    }

    function onStyleSettingsSaved(settings: StyleSettings) {
        if (diagramEditorApi.current) {
            diagramEditorApi.current.getStyleManager().applyStyleSettingsToSelection(settings);
            hideStyleSettingsDialog();
        }
    }

    function hideStyleSettingsDialog() {
        setStyleSettingsDialogState({open: false});
    }

    function publishNodesMovedEvent(parentNodeNewRelationships?: Array<RelationshipDto>) {
        if (nestingConnectionCreationDialog) {
            eventManager.publishEvent({
                type: EventType.NODES_MOVED,
                selectedNodes: nestingConnectionCreationDialog.selectedNodes,
                movedNodes: nestingConnectionCreationDialog.movedNodes,
                parentNode: nestingConnectionCreationDialog.parentCandidate,
                parentNodeNewRelationships: parentNodeNewRelationships,
                selectedConnections: nestingConnectionCreationDialog.selectedConnections,
                dimensions: nestingConnectionCreationDialog.dimensions
            });
        }
    }

    function publishNewNodeShowUpdateLabelEvent() {
        if (nestingConnectionCreationDialog?.isNewNode) {
            eventManager.publishEvent<NewNodeShowUpdateLabelEvent>({
                type: EventType.NEW_NODE_SHOW_UPDATE_LABEL,
                node: nestingConnectionCreationDialog.nodesToConnect[0],
                isUndoRedoResult: nestingConnectionCreationDialog.isUndoRedoResult,
                elementCreated: nestingConnectionCreationDialog.elementCreated!,
                renderedNodesCount: nestingConnectionCreationDialog.renderedNodesCount!,
            });
        }
    }

    const nodeLabelBounds = nodeLabelUpdateInfo?.clientBounds;
    const connectionLabelBounds = connectionLabelUpdateInfo?.clientBounds;
    const selectionMode = nodeContextMenuDialog ?
        (nodeContextMenuDialog.selectedNodes.length > 1 ? SelectionMode.MULTIPLE : SelectionMode.SINGLE)
        : SelectionMode.UNSELECTED;

    return (
        <React.Fragment>
            {errorDialogInfo &&
                <AlertDialog open={true}
                             type={AlertDialogType.ERROR}
                             onClose={() => setErrorDialogInfo(undefined)}
                             text={errorDialogInfo.text}
                             title={_transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_ERROR)}
                />
            }
            {concurrentUpdateDialogOpened &&
                <ConcurrentUpdateDialog
                    onSuccess={() => {
                        setConcurrentUpdateDialogOpened(false);
                        setCopyOfModelDialog({oldDiagramIdentifier: diagramEditorApi.current!.getModel().diagrams[0].diagramInfo.identifier});
                    }}
                    onClose={() => setConcurrentUpdateDialogOpened(false)}
                />
            }
            {copyOfModelDialog &&
                <CopyOfModelDialog
                    model={diagramEditorApi.current!.getModel()}
                    user={user!}
                    onSuccess={() => {
                        diagramBackupService.removeBackup(copyOfModelDialog?.oldDiagramIdentifier);
                        unregisterBeforeUnloadListener(showPromptBeforeUnloadCallback);
                        setCopyOfModelDialog(undefined);
                        setSaveButtonStatus(SaveButtonStatus.SAVE_NOT_NEEDED);
                    }}
                    onClosed={() => setCopyOfModelDialog(undefined)}
                />
            }
            {nodeContextMenuDialog &&
                <NodeContextMenu opened={true}
                                 onClose={() => setNodeContextMenuDialog(undefined)}
                                 onStylesClick={() => showStyleSettingsDialog(nodeContextMenuDialog.node)}
                                 node={nodeContextMenuDialog.node}
                                 selectedNodes={nodeContextMenuDialog.selectedNodes}
                                 selectedConnections={nodeContextMenuDialog.selectedConnections}
                                 selectionMode={selectionMode}
                                 clientCoordinates={nodeContextMenuDialog.clientCoordinates}
                                 transformedClientCoordinates={nodeContextMenuDialog.transformedClientCoordinates}
                                 diagramApi={diagramApi.current}
                                 diagramEditorApi={diagramEditorApi.current}
                                 eventManager={diagramEditorApi.current?.getEventManager()}
                                 mode={mode.mode}
                                 diagramId={diagramId}
                />
            }
            {connectionContextMenuDialog &&
                <ConnectionContextMenu opened={true}
                                       onClose={() => setConnectionContextMenuDialog(undefined)}
                                       onStylesClick={() => showStyleSettingsDialog(connectionContextMenuDialog.connection)}
                                       selectedNodes={connectionContextMenuDialog.selectedNodes}
                                       selectedConnections={connectionContextMenuDialog.selectedConnections}
                                       clientCoordinates={connectionContextMenuDialog.clientCoordinates}
                                       diagramApi={diagramApi.current}
                                       diagramEditorApi={diagramEditorApi.current}
                                       eventManager={diagramEditorApi.current?.getEventManager()}
                />
            }
            {paperContextMenuDialog &&
                <PaperContextMenu opened={true}
                                  onClose={() => setPaperContextMenuDialog(undefined)}
                                  clientCoordinates={paperContextMenuDialog.clientCoordinates}
                                  transformedClientCoordinates={paperContextMenuDialog.transformedClientCoordinates}
                                  eventManager={diagramEditorApi.current?.getEventManager()}
                                  mode={mode.mode}
                                  diagramId={diagramId}
                                  isGraphEmpty={diagramEditorApi.current?.getModel().graph.elements.length === 0}
                />
            }
            {elementDetailDialogInfo &&
                <ElementDetailDialog initialElementId={elementDetailDialogInfo.elementId}
                                     opened={true}
                                     disableRelationshipActions={true}
                                     onClosed={() => setElementDetailDialogInfo(undefined)}/>
            }

            {elementsGridDialogInfo &&
                <ElementListDialog dialogTitle={_transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_SELECTED_ELEMENTS_GRID)}
                                   isOpened={true}
                                   elements={elementsGridDialogInfo.elements}
                                   onDialogClosed={() => setElementsGridDialogInfo(undefined)}
                />
            }

            {nestingConnectionCreationDialog &&
                <NestingConnectionCreationDialog parentCandidate={nestingConnectionCreationDialog.parentCandidate}
                                                 nodesToConnect={nestingConnectionCreationDialog.nodesToConnect}
                                                 onConfirm={(event: any, createdRelationships: Array<RelationshipDto>) => {
                                                     publishNodesMovedEvent(createdRelationships);
                                                     publishNewNodeShowUpdateLabelEvent();
                                                     setNestingConnectionCreationDialog(undefined);
                                                 }}
                                                 onCancel={() => {
                                                     publishNodesMovedEvent([]);
                                                     publishNewNodeShowUpdateLabelEvent();
                                                     setNestingConnectionCreationDialog(undefined);
                                                 }}
                                                 diagramEditorApi={diagramEditorApi.current as IDiagramEditorApi}
                                                 diagramApi={(mode as IEditMode).diagramApi}
                />
            }
            {removeItemsDialogSettings &&
                <RemoveObjectsConfirmationDialog disableRemoveFromModel={removeItemsDialogSettings.disableRemoveFromModel}
                                                 onConfirm={(event, removeFromModel) => {
                                                     setRemoveItemsDialogSettings(undefined);
                                                     removeItemsDialogSettings.confirmCallback(event, removeFromModel);
                                                 }}
                                                 onCancel={(event) => {
                                                     setRemoveItemsDialogSettings(undefined);
                                                     removeItemsDialogSettings.cancelCallback(event);
                                                 }}
                />
            }
            {pasteDuplicateItemsDialog &&
                <PasteDuplicateItemsDialog itemsInfo={pasteDuplicateItemsDialog.itemsInfo}
                                           onCancel={() => {
                                               setPasteDuplicateItemsDialog(undefined);
                                           }}
                />
            }
            {confirmationDialog &&
                <ConfirmationDialog open={true}
                                    title={confirmationDialog.title}
                                    confirmationText={confirmationDialog.text}
                                    onConfirm={(event) => {
                                        setConfirmationDialog(undefined);
                                        confirmationDialog.confirmCallback(event);
                                    }}
                                    onReject={(event) => {
                                        setConfirmationDialog(undefined);
                                        confirmationDialog.cancelCallback(event);
                                    }}
                                    isModal={confirmationDialog.isModal}
                />
            }
            {hasUserSufficientElementPermissionsAlert && <AlertDialog open={true}
                                                    type={AlertDialogType.INFO}
                                                    title={_transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_ELEMENT_PERMISSIONS_ALERT_TITLE)}
                                                    text={_transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_ELEMENT_PERMISSIONS_ALERT_MESSAGE)}
                                                    onClose={() => {
                                                        setHasUserSufficientElementPermissionsAlert(false);
                                                        setIsUserPermittedToEdit(false);
                                                        setMode(createPreEditMode());
                                                    }}/>
            }
            {connectionTypeDialogInfo &&
                <ConnectionTypeSelectionDialog
                    allowedRelationshipTypes={connectionTypeDialogInfo.allowedRelationshipTypes}
                    hiddenRelationships={connectionTypeDialogInfo.hiddenRelationships}
                    eventPoint={connectionTypeDialogInfo.eventPoint}
                    onSelect={(definition: NewConnectionDefinition, event: any) => {
                        setConnectionTypeDialogInfo(undefined);
                        connectionTypeDialogInfo.onCreate(definition, event);
                    }}
                    onCancel={(event: any) => {
                        setConnectionTypeDialogInfo(undefined);
                        connectionTypeDialogInfo.onCancel(event);
                    }}
                />
            }
            {nodeLabelUpdateInfo && diagramEditorApi &&
                <div id={NODE_LABEL_UPDATE_DIV_ID} className={classes.nodeLabelUpdateDiv}
                     style={{top: nodeLabelBounds?.y, left: nodeLabelBounds?.x, width: nodeLabelBounds?.w}}>
                    <EditableTextField label={""}
                                       initialValue={nodeLabelUpdateInfo.label}
                                       doUpdate={(text: string) => nodeLabelUpdateInfo.doUpdate(text).toPromise()}
                                       onSuccessfulUpdate={() => setNodeLabelUpdateInfo(undefined)}
                                       editOnClick={true}
                                       onCancelChanges={() => {
                                           nodeLabelUpdateInfo.doCancel();
                                           setNodeLabelUpdateInfo(undefined);
                                       }}/>
                </div>
            }
            {connectionLabelUpdateInfo && diagramEditorApi &&
                <div id={CONNECTION_LABEL_UPDATE_DIV_ID} className={classes.connectionLabelUpdateDiv} style={{
                    top: connectionLabelBounds?.y,
                    left: connectionLabelBounds?.x,
                    width: connectionLabelBounds?.w
                }}>
                    <EditableTextField label={""}
                                       initialValue={connectionLabelUpdateInfo.label}
                                       doUpdate={(text: string) => connectionLabelUpdateInfo.doUpdate(text).toPromise()}
                                       onSuccessfulUpdate={() => setConnectionLabelUpdateInfo(undefined)}
                                       editOnClick={true}
                                       onCancelChanges={() => {
                                           connectionLabelUpdateInfo.doCancel();
                                           setConnectionLabelUpdateInfo(undefined);
                                       }}/>
                </div>
            }
            {styleSettingsDialogState.open &&
                <StyleSettingsDialog open={true}
                                     variant={styleSettingsDialogState.variant}
                                     mode={styleSettingsDialogState.mode}
                                     settings={styleSettingsDialogState.settings}
                                     onSave={onStyleSettingsSaved}
                                     onCancel={hideStyleSettingsDialog}/>
            }
            {showSaveChangesDialog &&
                <ConfirmationDialog open={true}
                                    title={_transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_SAVE_CHANGES)}
                                    confirmationText={_transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_SAVE_CHANGES_CONFIRM)}
                                    onConfirm={(e) => {
                                        setShowSaveChangesDialog(false);
                                        saveChanges(e, true);
                                    }}
                                    onReject={() => {
                                        setShowSaveChangesDialog(false);
                                        cancelChanges();
                                    }}
                                    onCancel={() => {
                                        setShowSaveChangesDialog(false);
                                    }}
                                    isModal={true}
                />
            }
            <Dialog open={true}
                    aria-labelledby="diagram-editor-dialog"
                    onClose={(event, reason) => {
                        if (!(mode.mode === RenderMode.EDIT && reason)) {
                            props.onClosed()
                        }
                    }}
                    fullScreen={true}
                    maxWidth={false}
                    disableEnforceFocus={true}
            >
                <DialogContent style={{padding: 0}}>
                    <Paper id={DIAGRAM_EDITOR_PAGE_ID} className={classes.page}>
                        <div className={clsx(classes.headerPageSegment, classes.pageMenu)}>
                            <Typography variant="h6" className={classes.pageTitle}>
                                {diagramName || _transl(DiagramTranslationKey.DIAGRAM_EDITOR_PAGE_LOADING_DIAGRAM)}
                            </Typography>
                            <div className={clsx(classes.pageMenuButtonsMenu)}>
                                <div>
                                    <div className={classes.pageMenuButtonsLeftMenu}>
                                        <TooltippedIconButton
                                            onClick={() => mode.mode === RenderMode.PRE_EDIT && switchToEditMode()}
                                            icon={<EditIcon/>}
                                            variant={mode.mode === RenderMode.EDIT ? "activated" : "standard"}
                                            disabled={!isUserPermittedToEdit}
                                            tooltip={mode.mode === RenderMode.PRE_EDIT ? _transl(CommonTranslation.EDIT) : undefined}
                                            />
                                        <ChatIconButton
                                            numberOfUnresolvedChats={numberOfUnresolvedChats}
                                            chatLayerVisible={chatLayerVisible}
                                            onChange={(visible) => switchChatLayerVisibility(visible)}/>
                                    </div>
                                    <div className={classes.pageMenuButtonsCenterMenu}>
                                    </div>
                                    <div className={classes.pageMenuButtonsRightMenu}>
                                        <IconButton aria-label="save" size={"small"} onClick={() => closeDialog()}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider/>
                        <div className={clsx(classes.controlPageSegment)}>
                            <DiagramEditorComponent diagramId={diagramId}
                                                    fetchDiagram={fetchDiagram}
                                                    onDiagramModelFetched={onDiagramModelFetched}
                                                    mode={mode}
                                                    saveButtonStatus={saveButtonStatus}
                                                    onChatsUpdated={onChatsUpdated}
                                                    user={user!}
                            />
                        </div>
                    </Paper>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

function fetchEditedDiagram(diagramId: string): Observable<IModelDto> {
    const dto: IModelDataExportDto = {
        diagramIdentifiers: [diagramId],
        elementIdentifiers: [],
        exportOrganizations: true,
        exportUnreferencedElementsAndRelationships: false,
    }
    return Api.exports.exportModelData(dto);
}
